import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from "../components/layout"
import Hero from '../components/hero'
import Seo from "../components/seo"

class AboutPage extends React.Component {
    render() {
    const [author] = get(this, 'props.data.allContentfulPerson.nodes')
  
      return (
        <Layout location={this.props.location}>
          <Seo title="About" />
          <Hero 
            title="About" 
            content={author.shortBio.shortBio}  
          />
          {/* <ArticlePreview posts={posts} /> */}
        </Layout>
      )
    }
  }
  

// const AboutPage = () => (
//   <Layout>
//     {/* <Seo title="404: Not found" /> */}
//     <h1>About</h1>
//     <p>You found the About page!</p>
//   </Layout>
// )

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      nodes {
        name
        shortBio {
          shortBio
        }
        title
        company
        heroImage: image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
          )
        }
      }
    }
  }
`
